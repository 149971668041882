.scope-options-popup {
  .scope-popover-container {
    max-height: 80vh;
    overflow: auto;
    width: 275px;
  }

  .ui.basic.scope-button-group {
    border: none;

    button.ui.button.scope-status-button {
      border: none;
      text-align: left;
      color: black;
      width: 90%;
    }

    .scope-status-delete {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: 10%;
      background-color: inherit;
    }

    .scope-status-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .scope-status-row.current {
      background-color: #e2e2e2 !important; // I am bad at css
      font-weight: 600;
    }

    .scope-status-icon {
      float: left;
      margin-right: 1.2em;
    }
  }
}